import React from 'react';
import { toast } from 'react-hot-toast';
import { BiLogOutCircle } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import BBButton from '@/base_blocks/bbbutton';
import { useStateManagerContext } from '@/components/state_manager/context';
import { stateUser } from '@/states/auth';
import { isLoggedIn } from '@/utils/auth';

const defaultShowText = false;

/**
 * PROPS
 * @param {boolean?} showText - Whether or not to show 'logout' text
 */
interface IPropsButtonLogout {
  showText?: boolean;
}

/**
 * BUTTON LOGOUT
 */
export default function ButtonLogout(Props: IPropsButtonLogout): React.ReactElement {
  const [user] = useRecoilState(stateUser);
  const { showText = defaultShowText } = Props;
  const stateManagerContext = useStateManagerContext();

  const logout = async () => {
    stateManagerContext.clearStateFunctions.clearAllStates(); // clear all states - token, user, etc.
    toast.success('Successfully logged out.');
  };

  /**
   * RENDER
   */
  return isLoggedIn(user) ? (
    <BBButton text={showText ? 'Logout' : undefined} icon={{ icon: <BiLogOutCircle /> }} onClick={logout} />
  ) : (
    <></>
  );
}
