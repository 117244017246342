import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { IoIosLogIn } from 'react-icons/io';
import { IoCreateOutline } from 'react-icons/io5';
import BBButton from '@/base_blocks/bbbutton';
import styles from '@/components/buttons/buttons_auth/styles.module.scss';
import Routes from 'routes';

/**
 * PROPS
 *
 * @param {boolean=} showText - Show text on button
 * @param {boolean=} elevation - Whether to show elevation
 * @param {string=} className - Class names to add to button
 */
interface IPropsButtonsAuth {
  showText?: boolean;
  elevation?: boolean;
  className?: string;
}

/**
 * BUTTONS AUTH
 */
export default function ButtonsAuth(Props: IPropsButtonsAuth): React.ReactElement {
  const { showText = false, elevation = false, className } = Props;
  const router = useRouter();

  /**
   * RENDER
   */
  return (
    <div className={classNames(className, styles.container)}>
      <BBButton
        variant="primary"
        text={showText ? 'Login' : undefined}
        icon={{ icon: <IoIosLogIn /> }}
        elevation={elevation ? 'medium' : 'none'}
        onClick={() => router.push(Routes.routeLogin())}
      />
      <BBButton
        variant="secondary"
        icon={{ icon: <IoCreateOutline /> }}
        text={showText ? 'Signup' : undefined}
        elevation={elevation ? 'medium' : 'none'}
        onClick={() => router.push(Routes.routeSignup())}
      />
    </div>
  );
}
