import React from 'react';
import styles from '@/components/loading/loading_page/styles.module.scss';

/**
 * LOADING PAGE
 */
export default function LoadingPage() {
  /**
   * RENDER
   */
  return (
    <div className={styles.loading_page}>
      <span className={styles.loader} />
    </div>
  );
}
