import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import BBNavbar from '@/base_blocks/bbnavbar';
import BBNavbarItem from '@/base_blocks/bbnavbar_item';
import Logo from '@/images/dn-logo.png';
import { stateUser } from '@/states/auth';
import Routes from 'routes';

/**
 * PROPS
 * @param {string} title - title to use for navbar
 */
interface IPropsNavbarMain {
  title?: string;
}

/**
 * NAVBAR MAIN
 */
export default function NavbarMain(Props: IPropsNavbarMain): React.ReactElement {
  const { title = "Dan's Blog" } = Props;
  const [user] = useRecoilState(stateUser);
  const [navbarChildren, setNavbarChildren] = useState<React.ReactElement[]>([]);

  useEffect(() => {
    setNavbarChildren([
      <BBNavbarItem key="Home" title="Home" href={Routes.routeHome()} />,
      <BBNavbarItem key="Blog" title="Blog" href={Routes.routeBlogResults()} />,
      !!user && user.is_staff ? (
        <BBNavbarItem key="Admin" title="Admin" href={Routes.routeAdmin()} />
      ) : (
        <React.Fragment key="Admin" />
      ),
      <BBNavbarItem key="Contact" title="Contact" href={Routes.routeContact()} />,
    ]);
  }, [user]);

  /**
   * RENDER
   */
  return (
    <BBNavbar title={title} imageSrc={Logo.src}>
      {navbarChildren}
    </BBNavbar>
  );
}
