import Image from 'next/image';
import React from 'react';
import BBLink from '@/base_blocks/bblink';
import BBText from '@/base_blocks/bbtext';
import ThemeSwitcher from '@/components/buttons/theme_switcher';
import styles from '@/components/footer/styles.module.scss';
import ImageDNLogo from '@/images/dn-logo.png';

/**
 * FOOTER
 */
export default function Footer(): React.ReactElement {
  const year = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <Image src={ImageDNLogo} alt="DN Logo" height={64} width={64} />
      <BBLink href="https://danielnazarian.com" external>
        Daniel Nazarian
      </BBLink>
      <BBText color="grey_light" size="tiny">
        Copyright {year} &#169;
      </BBText>
      <ThemeSwitcher />
    </div>
  );
}
