import { useTheme } from 'next-themes';
import React from 'react';
import { BsFillMoonFill, BsFillSunFill } from 'react-icons/bs';
import BBButton from '@/base_blocks/bbbutton';
import styles from '@/components/buttons/theme_switcher/styles.module.scss';

/**
 * THEME SWITCHER
 */
export default function ThemeSwitcher(): React.ReactElement {
  const { theme, setTheme } = useTheme();
  /**
   * RENDER
   */
  return (
    <div className={styles.main}>
      {theme == 'light' ? (
        <BBButton onClick={() => setTheme('dark')} variant={'primary'} icon={{ icon: <BsFillMoonFill /> }} />
      ) : (
        <BBButton onClick={() => setTheme('light')} variant={'secondary'} icon={{ icon: <BsFillSunFill /> }} />
      )}
    </div>
  );
}
